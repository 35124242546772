




































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Volunteers extends Vue {
    groups: any[] = [
        {
            name: "COACHES",
            people: [
                "Lennie McMillian",
                "Stuart James",
                "Yasmin Okic",
                "Niki Synnott",
                "Rebecca Mahon",
                "Keith Coffey",
                "Sladjan Kutjievac",
                "Dave Kearney",
                "Piotr Peczek",
                "Paddy O’Malley",
                "Alan Gunning",
                "Haris Sikorsis",
                "Zoran Stankovic",
                "Padraig McDonagh",
                "Carl Harrington",
                "Jorge Barata",
                "Stephen McGuirk",
                "Precious Gabriel",
                "Urosh Kutjievac",
                "Bogdan Morearan",
                "Juan Carlos Alverez",
                "Tara Gordon",
            ],
        },
        {
            name: "ASSISTANT COACHES",
            people: [
                "Nicole Kenny",
                "Jasmine McGuinness",
                "Niamh Cameron",
                "John Byrne",
                "Dave Treacy",
                "Padraic Bermingham",
                "Frankie O’Sullivan",
                "Jack Sweeney",
                "Ruairi O’Sullivan",
                "Urosh Kutijevac",
                "Frank O’Sullivan",
                "Wiktoria Wisniowska",
                "Gavin Clare",
                "Hannah Dardis",
                "Dario Poslekl",
                "David Williamson",
                "Ronan Holohon",
                "Matt Thurstan",
                "Duane Cosgrove",
            ],
        },
        {
            name: "TEAM MANAGERS",
            people: [
                "Sue Stapleton",
                "Declan Coakley",
                "Irene Rothwell",
                "Bernice Vaz",
                "Aisling Olden",
                "Olga Kearney",
                "Deirdre Byrne",
                "Inga Sabiene",
                "Milosh Kutijevac",
                "Karen Dardis",
                "Michael Flannery",
                "Hazel McLoughlin",
                "Natalija Bartiuk",
                "Irene Amabili",
                "Ger O’Shaughnessy",
                "Elton Dinesh",
                "Aisling Olden",
                "Geraldine Cleary",
                "Enda McKenna",
                "Laura McKenna",
                "Alice Doyle",
                "Laura Grant",
                "Paul Hyland",
                "Alan Gunning",
            ],
        },
        {
            name: "CHILDRENS OFFICERS",
            people: ["Mel Deery"],
        },
        {
            name: "DATA PROTECTION OFFICER",
            people: ["Emma Kenny"],
        },
        {
            name: "REFEREE MANAGER",
            people: ["Milica Stankovic"],
        },
        {
            name: "TABLE OFFICIALS REPRESENTATIVE",
            people: ["Mira Kutijevac"],
        },
        {
            name: "CONSULTANT",
            people: ["Eve Farrelly"],
        },
        {
            name: "ONGAR CHASERS' BOARD",
            people: [
                "Padraic Bermingham - Chairman",
                "Sladjan Kutijevac - Secretary",
                "Deirdre Byrne - Treasurer",
                "Zoran Stankovic - Coaching Coordinator",
                "Yasmin Okic - Coaching Coordinator",
                "Wiktoria Wisniowska - Communications",
                "Mike Flannery - Fundraising",
            ],
        },
    ];
}
