























































import { Component, Vue } from "vue-property-decorator";
import { VenuesApi, Venue } from "@/store/venues-api";

@Component({ components: {} })
export default class Volunteers extends Vue {
    venueStore = new VenuesApi();
    isMounted = false;
    center: Venue = { lat: 53.39, lng: -6.45 } as Venue; // hetss
    members: any[] = [
        {
            name: "Padraic Bermingham",
            title: "Chairman",
            email: "chairman@ongarchasers.com",
        },
        {
            name: "Sladjan Kutijevac",
            title: "Secretary (The President)",
            email: "secretary@ongarchasers.com",
        },
        {
            name: "Zoran Stankovic",
            title: "Coaching Coordinator",
            email: "coaches@ongarchasers.com",
        },
        {
            name: "Yasmin Okic",
            title: "Coaching Coordinator",
            email: "coaches@ongarchasers.com",
        },
        {
            name: "Deirdre Byrne",
            title: "Treasurer",
            email: "treasurer@ongarchasers.com",
        },
        {
            name: "Mike Flannery",
            title: "Fund Raising",
        },
        {
            name: "Wiktoria Wisniowska",
            title: "Communications",
        },
    ];
    venues: Venue[] = [];
    infoWindowPos: Venue = { lng: 0, lat: 0 } as Venue;
    infoWinOpen = false;
    currentMidx = -1;
    infoOptions = {
        content: "",
        //offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
            width: 0,
            height: -35,
        },
    };

    mounted() {
        return this.venueStore
            .getAll(true)
            .then((res) => (this.venues = res.data))
            .then(() => (this.isMounted = true));
    }

    mailTo(email: string) {
        return `mailto:${email}`;
    }

    toggleInfoWindow(venue: Venue, idx: number) {
        this.infoWindowPos = venue;
        this.infoOptions.content = venue.name;

        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {
            this.infoWinOpen = !this.infoWinOpen;
        } else {
            //if different marker set infowindow to open and reset current marker index
            this.infoWinOpen = true;
            this.currentMidx = idx;
        }
    }
}
