import { axios } from "./axios";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import store from "../store";
import FileUtils from "@/common/utils/fileUtils";

@Module({
    dynamic: true,
    namespaced: true,
    store,
    name: "sponsors",
})
export class SponsorsModule extends VuexModule {
    @Action
    getAll() {
        return axios.get<Sponsor[]>("/sponsors");
    }

    @Action
    save({ sponsor }: { sponsor: Sponsor }) {
        const formData = FileUtils.objectToFormData(sponsor);
        if (!!sponsor.id && sponsor.id > -1) {
            return axios.put("/sponsors", formData);
        } else {
            return axios.post("/sponsors", formData);
        }
    }

    @Action
    delete({ sponsorId }: { sponsorId: number }) {
        return axios.delete(`/sponsors/${sponsorId}`);
    }
}

export interface Sponsor {
    id: number;
    name: string;
    sponsorUri: string;
    mediaUri: string;
    details: string;
    level: number;
    imageUpload: Blob;
}
