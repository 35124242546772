
















































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store/profile-module";
import { SponsorsModule, Sponsor } from "@/store/sponsors-module";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";
import SponsorsForm from "./SponsorsForm.vue";

@Component({ components: { CDeleteConfirm, SponsorsForm } })
export default class Sponsors extends Vue {
    sponsorsStore = getModule(SponsorsModule);
    profileStore = getModule(ProfileModule);
    sponsors: Sponsor[] = [];
    loading = true;

    get hasPermission() {
        return this.profileStore.profile.admin;
    }

    mounted() {
        this.get();
    }

    get() {
        this.loading = false;

        return this.sponsorsStore
            .getAll()
            .then((res) => (this.sponsors = res.data))
            .then(() => (this.loading = false));
    }

    upsertItem(item: Sponsor) {
        (this.$refs.upsert as SponsorsForm).open(item);
    }

    confirmDelete(item: Sponsor) {
        (this.$refs.delete as CDeleteConfirm).open(item);
    }

    deleteConfirmed(item: Sponsor) {
        this.loading = true;
        this.sponsorsStore
            .delete({ sponsorId: item.id })
            .then(() => this.get())
            .finally(() => (this.loading = false));
    }
}
