






















































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { SponsorsModule, Sponsor } from "@/store/sponsors-module";
import CForm from "@/common/components/CForm.vue";
import { required, requiredIf } from "@/common/validators/rules";

@Component({ components: { CForm } })
export default class SponsorsForm extends Vue {
    sponsorsStore = getModule(SponsorsModule);
    validators = { required, requiredIf };
    dialog = false;
    loading = false;
    item: Sponsor = {} as Sponsor;

    get formTitle() {
        return !this.item.id ? "New Sponsor" : "Edit Sponsor";
    }

    open(item: Sponsor) {
        this.item = Object.assign({}, item);
        this.dialog = true;
    }

    close() {
        this.dialog = false;
    }

    save() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        this.loading = true;
        return this.sponsorsStore
            .save({ sponsor: this.item })
            .then(() => (this.dialog = false))
            .then(() => this.$emit("updated"))
            .finally(() => (this.loading = false));
    }
}
